const apparelData = {
  // COMBO PRODUCT ----------------------------------------------------------

  // "gid://shopify/Product/7492219207872": {
  //   type: "apparel",
  //   title: "Muscle Tank",
  //   img: [
  //     "combo-muscle-tank__antique-denim_grey",
  //     "combo-muscle-tank__stonewash-denim",
  //     "combo-muscle-tank__antique-denim-grey",
  //   ],
  //   collection: "apparel",
  //   collectionInfo: "apparel-shirt",
  //   care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
  //   sizeGuide: {
  //     horizontal: [
  //       {
  //         name: "Body Length",
  //         imperial: [24.25, 24.875, 25.5, 26.125, 26.75, 27.375],
  //       },
  //       {
  //         name: "Chest Width (Laid Flat)",
  //         imperial: [15.125, 16.125, 17.125, 18.125, 19.625, 21.125],
  //       },
  //     ],
  //     vertical: ["XS", "S", "M", "L", "XL", "2XL"],
  //   },
  // },

  // FALL COLLECTION ----------------------------------------------------

  "gid://shopify/Product/7374582513856": {
    type: "apparel",
    title: "Rockstar Crop Top",
    img: ["crop-top__black"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length", imperial: [19.5, 20, 22, 24, 26] },
        { name: "Body Width", imperial: [16.25, 17.75, 19.5, 21.5, 23.5] },
      ],
      vertical: ["S", "M", "L", "XL", "2XL"],
    },
    hidden: true,
  },
  "gid://shopify/Product/7374596407488": {
    type: "apparel",
    title: "Stop Drop and Betty Rock Crop Top",
    img: ["crop-sdbr__teal"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length", imperial: [19.5, 20, 22, 24, 26] },
        { name: "Body Width", imperial: [16.25, 17.75, 19.5, 21.5, 23.5] },
      ],
      vertical: ["S", "M", "L", "XL", "2XL"],
    },
    hidden: true,
  },
  "gid://shopify/Product/7374599618752": {
    type: "apparel",
    title: "Rock Your Life Crop Top",
    img: ["crop-ryl__teal"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length", imperial: [19.5, 20, 22, 24, 26] },
        { name: "Body Width", imperial: [16.25, 17.75, 19.5, 21.5, 23.5] },
      ],
      vertical: ["S", "M", "L", "XL", "2XL"],
    },
    hidden: true,
  },
  "gid://shopify/Product/7374601781440": {
    type: "apparel",
    title: "All or Something Crop Top",
    img: ["crop-aos__heather-grey"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length", imperial: [19.5, 20, 22, 24, 26] },
        { name: "Body Width", imperial: [16.25, 17.75, 19.5, 21.5, 23.5] },
      ],
      vertical: ["S", "M", "L", "XL", "2XL"],
    },
    hidden: true,
  },
  "gid://shopify/Product/7374611513536": {
    type: "apparel",
    title: "Fleece-lined Hoodie",
    img: ["hoodie-rockstar__black"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash cold with like colors, normal cycle only. Do not bleach. Tumble dry low. Do not iron design.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length", imperial: [26, 27, 28, 29, 30, 31] },
        { name: "Chest Width (Laid Flat)", imperial: [19, 20, 21, 22, 23, 24] },
        {
          name: "Sleeve Length (From Center Back)",
          imperial: [31.25, 32.25, 33.25, 34.25, 35.25, 36.25],
        },
      ],
      vertical: ["XS", "S", "M", "L", "XL", "2XL"],
    },
  },
  "gid://shopify/Product/7374612725952": {
    type: "apparel",
    title: "All or Something Racerback Tank",
    img: ["tank-aos__vintage-black", "tank-aos__purple-rush"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
    sizeGuide: {
      horizontal: [
        {
          name: "Body Length",
          imperial: [25.875, 26.5, 27.125, 27.75, 28.375, 29],
        },
        {
          name: "Chest Width (Laid Flat)",
          imperial: [15, 16, 17, 18, 19.5, 21],
        },
      ],
      vertical: ["XS", "S", "M", "L", "XL", "2XL"],
    },
  },
  "gid://shopify/Product/7374616101056": {
    type: "apparel",
    title: "Rock Your Life Racerback Tank",
    img: ["tank-ryl__vintage-hot-pink"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
    sizeGuide: {
      horizontal: [
        {
          name: "Body Length",
          imperial: [25.875, 26.5, 27.125, 27.75, 28.375, 29],
        },
        {
          name: "Chest Width (Laid Flat)",
          imperial: [15, 16, 17, 18, 19.5, 21],
        },
      ],
      vertical: ["XS", "S", "M", "L", "XL", "2XL"],
    },
  },
  // "gid://shopify/Product/7374623080640": {
  //   type: "apparel",
  //   title: "Rock Your Life Muscle Tank",
  //   img: ["muscle-tank-ryl__antique-denim_grey"],
  //   collection: "apparel",
  //   collectionInfo: "apparel-shirt",
  //   care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
  //   sizeGuide: {
  //     horizontal: [
  //       {
  //         name: "Body Length",
  //         imperial: [24.25, 24.875, 25.5, 26.125, 26.75, 27.375],
  //       },
  //       {
  //         name: "Chest Width (Laid Flat)",
  //         imperial: [15.125, 16.125, 17.125, 18.125, 19.625, 21.125],
  //       },
  //     ],
  //     vertical: ["XS", "S", "M", "L", "XL", "2XL"],
  //   },
  //
  // },
  // "gid://shopify/Product/7374635368640": {
  //   type: "apparel",
  //   title: "Stop, Drop and Betty Rock Muscle Tank",
  //   img: [`muscle-tank-sdbr__antique-denim_grey`],
  //   collection: "apparel",
  //   collectionInfo: "apparel-shirt",
  //   care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
  //   sizeGuide: {
  //     horizontal: [
  //       {
  //         name: "Body Length",
  //         imperial: [24.25, 24.875, 25.5, 26.125, 26.75, 27.375],
  //       },
  //       {
  //         name: "Chest Width (Laid Flat)",
  //         imperial: [15.125, 16.125, 17.125, 18.125, 19.625, 21.125],
  //       },
  //     ],
  //     vertical: ["XS", "S", "M", "L", "XL", "2XL"],
  //   },
  // },
  // "gid://shopify/Product/7375209332928": {
  //   type: "apparel",
  //   title: "All or Something Muscle Tank",
  //   img: [`muscle-tank-aos__stonewash-denim`],
  //   collection: "apparel",
  //   collectionInfo: "apparel-shirt",
  //   care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
  //   sizeGuide: {
  //     horizontal: [
  //       {
  //         name: "Body Length",
  //         imperial: [24.25, 24.875, 25.5, 26.125, 26.75, 27.375],
  //       },
  //       {
  //         name: "Chest Width (Laid Flat)",
  //         imperial: [15.125, 16.125, 17.125, 18.125, 19.625, 21.125],
  //       },
  //     ],
  //     vertical: ["XS", "S", "M", "L", "XL", "2XL"],
  //   },
  // },
  // "gid://shopify/Product/7375221915840": {
  //   type: "apparel",
  //   title: "Rock Your Life Slouchy T",
  //   img: [`slouchy-t-ryl__black`],
  //   collection: "apparel",
  //   collectionInfo: "apparel-shirt",
  //   care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low or hang to dry. Do not iron over decoration.",
  //   sizeGuide: {
  //     horizontal: [
  //       {
  //         name: "Body Length",
  //         imperial: [25.25, 25.75, 26.375, 27, 27.625],
  //       },
  //       {
  //         name: "Chest Width (Laid Flat)",
  //         imperial: [20, 21.5, 23.5, 25.5, 27.5],
  //       },
  //     ],
  //     vertical: ["S", "M", "L", "XL", "2XL"],
  //   },
  // },
  "gid://shopify/Product/7375232696512": {
    type: "apparel",
    title: "All or Something T (unisex)",
    img: [`aos-unisex-t__storm-grey`],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash warm, inside out,  with like colors. Only non-cholorine bleach. Tumble dry low. Medium  iron. Do not iron decoration. Do not dry clean.",
    sizeGuide: {
      horizontal: [
        {
          name: "Body Length at Back",
          imperial: [27, 28, 29, 30, 31, 32],
        },
        {
          name: "Chest Width",
          imperial: [16.5, 18, 20, 22, 24, 26],
        },
      ],
      vertical: ["XS", "S", "M", "L", "XL", "2XL"],
      notes: [
        {
          title: "Body Length at Back",
          content: "Measured from high point shoulder to finished hem at back.",
        },
        {
          title: "Chest Width",
          content: "Measured across the chest one inch below armhole when laid flat.",
        },
      ],
    },
  },

  // REGULAR COLLECTION ----------------------------------------------------

  "gid://shopify/Product/6922397188288": {
    type: "apparel",
    title: "#flawsome Hoodie Tank",
    img: ["hoodietankflawsome__heathered-grey_black"],
    collection: "apparel",
    collectionInfo: "apparel-tank",
    care: "Machine wash cold with like  colors. Do not use fabric softener. Only non-chlorine bleach, when  needed. Tumble dry low. Remove promptly. Cool iron, if necessary. Do not  dry clean.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length at Back", imperial: [25, 25.5, 26, 26.5, 27] },
        { name: "Bust", imperial: [17.75, 18.75, 19.75, 21.25, 22.25] },
      ],
      vertical: ["XS", "S", "M", "L", "XL"],
      notes: [
        {
          title: "Body Length at Back",
          content: "Measured from high point shoulder to finished hem at back.",
        },
        { title: "Bust", content: "Measured one inch below armhole." },
      ],
    },
  },
  // TODO: IF UNCOMMENTING THIS PRODUCT, UNCOMMENT IT IN BOTH youMayAlsoLike and suggestedProducts
  // "gid://shopify/Product/6922391388352": {
  //   type: "apparel",
  //   title: "#flawsome Racerback Tank",
  //   img: ["tankflawsome__desert-pink", "tankflawsome__tahiti-blue"],
  //   collection: "apparel",
  //   collectionInfo: "apparel-tank",
  //   care: "Machine wash cold. Only non-chlorine bleach when needed. Tumble dry low. Do not iron over decoration.",
  //   sizeGuide: {
  //     horizontal: [
  //       { name: "Body Length", imperial: [25.86, 26.5, 27.13, 27.75, 28.36] },
  //       { name: "Chest Width (Laid Flat)", imperial: [15, 16, 17, 18, 19.5] },
  //     ],
  //     vertical: ["XS", "S", "M", "L", "XL"],
  //   },
  // },
  "gid://shopify/Product/6922418716864": {
    type: "apparel",
    title: "#flawsome Snap Back Trucker Hat",
    img: ["truckerhatflawsome__grey_white_pink-ombre"],
    collection: "apparel",
    collectionInfo: "apparel-other",
    care: "Rinse by hand in a basin of cool  water with a small amount of laundry detergent. Soak up to 5 minutes. Treat stains by applying a toothbrush with detergent directly to the  area. Pat dry with a towel to remove excess water, reshape and allow to  air dry on a towel or hang up. Do not twist or wring.",
    sizeGuide: {
      horizontal: [
        { name: "Crown Height", imperial: [6.5] },
        { name: "Size", imperial: ["OSFM"] },
        { name: "Visor Length", imperial: [2.75] },
        { name: "Width of Front Two Panels", imperial: [7.5] },
      ],
      vertical: [],
      notes: [{ title: "", content: "All sizing may vary by +/- .50 up to 1.50 CM" }],
    },
  },
  "gid://shopify/Product/6922420584640": {
    type: "apparel",
    title: "Betty Rocker Beanie (Prototype Sample)",
    img: ["vintagebeanie__athletic-grey"],
    collection: "apparel",
    collectionInfo: "apparel-other",
    care: "Handwash or wash on gentle cycle with like colors. Hang dry or tumble dry low on low heat.",
  },
  // "gid://shopify/Product/6922422255808": {
  //   type: "apparel",
  //   title: "Betty Rocker Hoodie Tank (Prototype Sample)",
  //   img: ["vintagehoodietank__dark-grey_black"],
  //   collection: "apparel",
  //   collectionInfo: "apparel-tank",
  //   care: "Handwash or wash on gentle cycle with like colors. Hang dry or tumble dry low on low heat due to the plastic toggles.",
  //   sizeGuide: {
  //     horizontal: [
  //       { name: "Half chest", imperial: [15, 15.5, 16, 18] },
  //       { name: "Back length", imperial: [26, 26.75, 27, 28.5] },
  //     ],
  //     vertical: ["XS", "S", "M", "L"],
  //   },
  // },
  "gid://shopify/Product/6922271916224": {
    type: "apparel",
    title: "Classic Betty Rocker Kitchen Apron",
    img: ["apron__dark-grey"],
    collection: "apparel",
    collectionInfo: "apparel-other",
    care: "Machine wash hot, at or below 60 degrees C/140 degrees F. Do not bleach. Tumble dry low, iron on low heat setting.",
    sizeGuide: {
      horizontal: [
        { name: "Length", imperial: ["34"] },
        { name: "Width at apron strings", imperial: [28] },
        { name: "Apron strings (each)", imperial: [36] },
        { name: "Adjustable Neck string", imperial: [30] },
      ],
      vertical: [],
    },
  },
  //WHEN UNCOMMENTING THIS PRODUCT, UNCOMMENT IT IN BOTH youMayAlsoLike and SuggestedProducts
  // "gid://shopify/Product/6922404495552": {
  //   type: "apparel",
  //   title: "Short Sleeve T",
  //   img: ["tshirtsdbr__royal-blue", "tshirtsdbr__charcoal-black"],
  //   collection: "apparel",
  //   collectionInfo: "apparel-shirt",
  //   care:
  //     "Machine wash warm, inside out,  with like colors. Only non-cholorine bleach. Tumble dry low. Medium  iron. Do not iron decoration. Do not dry clean.",
  //   sizeGuide: {
  //     horizontal: [
  //       { name: "Size", imperial: [0.67, 0.8, 0.86, 0.89] },
  //       { name: "Body Length at Back", imperial: [26, 26.5, 27.13, 27.75] },
  //       { name: "Chest Width", imperial: [15.75, 16.5, 17.5, 18.5] },
  //     ],
  //     vertical: ["S", "M", "L", "XL"],
  //     notes: [
  //       {
  //         title: "Body Length at Back",
  //         content: "Measured from high point shoulder to finished hem at back.",
  //       },
  //       {
  //         title: "Chest Width",
  //         content:
  //           "Measured across the chest one inch below armhole when laid flat.",
  //       },
  //     ],
  //   },
  // },
  "gid://shopify/Product/6922419568832": {
    type: "apparel",
    title: "Stop, Drop and Betty Rock Racerback Tank (limited)",
    img: ["vintagetanksdbr__dark-grey"],
    collection: "apparel",
    collectionInfo: "apparel-tank",
    care: "Machine wash cold. Use only non-chlorine bleach when needed. Tumble dry low. Do not iron. Do not dry clean.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length", imperial: [25.75, 26.25, 27, 27.5] },
        { name: "Body Length Tolerance", imperial: [0.5, 0.5, 0.5, 0.5] },
        { name: "Chest Tolerance", imperial: [0.5, 0.5, 0.5, 0.5] },
        { name: "Chest Width (Laid Flat)", imperial: [13, 14, 15, 16] },
      ],
      vertical: ["XS", "S", "M", "L"],
    },
  },
  "gid://shopify/Product/6922399744192": {
    type: "apparel",
    title: "Super Soft Lightweight Fleece-lined Hoodie",
    img: ["hoodiesdbr__dusty-rose"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash cold with like colors, normal cycle only. Do not bleach. Tumble dry low. Do not iron design.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length", imperial: [26, 27, 28, 29, 30] },
        { name: "Chest Width (Laid Flat)", imperial: [19, 20, 21, 22, 23] },
        {
          name: "Sleeve Length (From Center Back)",
          imperial: [31.25, 32.25, 33.25, 34.25, 35.25],
        },
      ],
      vertical: ["XS", "S", "M", "L", "XL"],
    },
  },
  "gid://shopify/Product/6922417176768": {
    type: "apparel",
    title: "Unisex Super Soft short sleeve T",
    img: ["unisextshirtsdbr__athletic-grey"],
    collection: "apparel",
    collectionInfo: "apparel-shirt",
    care: "Machine wash warm, inside out,  with like colors. Only non-cholorine bleach. Tumble dry low. Medium  iron. Do not iron decoration. Do not dry clean.",
    sizeGuide: {
      horizontal: [
        { name: "Body Length at Back", imperial: [27, 28, 29, 30, 31] },
        { name: "Chest Width", imperial: [16.5, 18, 20, 22, 24] },
      ],
      vertical: ["XS", "S", "M", "L", "XL"],
      notes: [
        {
          title: "Body Length at Back",
          content: "Measured from high point shoulder to finished hem at back.",
        },
        {
          title: "Chest Width",
          content: "Measured across the chest one inch below armhole when laid flat.",
        },
      ],
    },
  },

  // MERCHANDISE ----------------------------------------------------

  "gid://shopify/Product/6978687631552": {
    type: "merchandise",
    title: "Stop, Drop and Betty Rock Shaker Bottle",
    img: ["sdbr-bottle"],
    collection: "merchandise",
    collectionInfo: "merchandise",
    care: "Wash with warm, soapy water. Rinse and dry. Dishwasher safe.",
  },
  // "gid://shopify/Product/7024694788288": {
  //   type: "merchandise",
  //   title: "Love Your Body Shaker Bottle",
  //   img: ["love-you-body-bottle"],
  //   collection: "merchandise",
  //   collectionInfo: "merchandise",
  //   care: "Wash with warm, soapy water. Rinse and dry. Dishwasher safe.",
  // },
  "gid://shopify/Product/6978694152384": {
    type: "merchandise",
    title: "30-Day Challenge Tracker Erasable Board",
    img: ["memory-board"],
    collection: "merchandise",
    collectionInfo: "merchandise",
    care: "Use water and a paper towel to wipe off text, and wipe dry.",
  },
  "gid://shopify/Product/7353488703680": {
    type: "merchandise",
    title: "All or Something Reusable Grocery Tote",
    img: ["all-or-something-tote"],
    collection: "merchandise",
    collectionInfo: "merchandise",
  },
  // "gid://shopify/Product/6978698215616": {
  //   type: "merchandise",
  //   title: "Eat Better Reusable Grocery Tote",
  //   img: ["grocery-tote"],
  //   collection: "merchandise",
  //   collectionInfo: "merchandise",
  // },
}

export default apparelData
