import React from "react"

// Components
import { Link } from "gatsby"
import DiscountCode from "./Items/DiscountCode"
import DiscountBtn from "./Items/DiscountBtn"
import DiscountTxt from "./Items/DiscountTxt"

/**
 * @param theme - theme
 * @param modifierClass - class for specific style
 * @param isCart - is the box located in the cart
 * */
const DiscountCodeBox = ({ theme, modifierClass, isCart }) => {
  const discountType = "bg" // pink || bg

  return (
    <>
      {theme.shipping && !theme.discount && (
        <>
          {isCart ? (
            <Link
              to={"/category/supplements"}
              role="button"
              className={`discount--${discountType} discount-box ${modifierClass}`}
            >
              <div className="row align-items-end">
                <div className="col-12 col-sm-8 col-md-6">
                  <DiscountCode theme={theme} />
                  <DiscountTxt theme={theme} />
                  <DiscountBtn theme={theme} />
                </div>
                <div className="col-12 col-sm-4 col-md-6">
                  <img
                    src="/images/discounts/discount-products-md.png"
                    alt="Supplements."
                    width={283}
                    height={147}
                    className="discount-box__img d-none d-md-block"
                  />
                  <img
                    src="/images/discounts/discount-products-sm.png"
                    alt="Supplements."
                    width={187}
                    height={167}
                    className="discount-box__img d-block d-md-none"
                  />
                </div>
              </div>
            </Link>
          ) : (
            <Link
              to={"/category/supplements"}
              role="button"
              onClick={theme.handleSidebar}
              className={`discount--${discountType} discount--bg-sm discount-box ${modifierClass}`}
            >
              {/*Discount Code*/}
              <DiscountCode theme={theme} />

              <div className="row">
                <div className="col-11 col-sm-10">
                  <DiscountTxt theme={theme} />
                  <DiscountBtn theme={theme} />
                </div>
              </div>

              <img
                src="/images/discounts/discount-products-sm.png"
                alt="Supplements."
                width={187}
                height={167}
                className="discount-box__img"
              />
            </Link>
          )}
        </>
      )}
    </>
  )
}
export default DiscountCodeBox
