import React, { useEffect, useState } from "react"
/**
 * @param shopifyProduct - data for the shopify product
 * @param orderId - the order of the component
 * @param selectedVariant - the order of the component
 * @param handleVariantSelect - handling the variant selection
 * */
const OfferVariations = ({ shopifyProduct, orderId, selectedVariant, handleVariantSelect }) => {
  const [intervals, setIntervals] = useState([])
  const [types, setTypes] = useState([])
  const [description, setDescription] = useState([])

  const [selectedInterval, setSelectedInterval] = useState("")
  const [selectedType, setSelectedType] = useState("")

  useEffect(() => {
    if (!shopifyProduct) return

    if (shopifyProduct.options && shopifyProduct.options.length) {
      // Interval
      const filterInterval = shopifyProduct.options.filter(option => option.name.toLowerCase() === "interval")
      if (filterInterval.length) {
        setIntervals(filterInterval[0].values)
        setDescription([
          "2 bottles + 15% discount <span class='d-inline-block'>+ free shipping</span>",
          "4 bottles + 30% discount <span class='d-inline-block'>+ free shipping</span>",
        ])
      }

      // Type
      const filterType = shopifyProduct.options.filter(option => option.name.toLowerCase() === "type")
      if (filterType.length) {
        setTypes(filterType[0].values)
      }
    }
  }, [shopifyProduct])

  useEffect(() => {
    if (!selectedVariant) return

    if (selectedVariant.selectedOptions && selectedVariant.selectedOptions.length) {
      // Interval
      const filterVariantInterval = selectedVariant.selectedOptions.filter(
        option => option.name.toLowerCase() === "interval",
      )
      if (filterVariantInterval.length) {
        setSelectedInterval(filterVariantInterval[0].value.toLowerCase())
      }

      // Type
      const filterVariantType = selectedVariant.selectedOptions.filter(option => option.name.toLowerCase() === "type")
      if (filterVariantType.length) {
        setSelectedType(filterVariantType[0].value.toLowerCase())
      }
    }
  }, [selectedVariant])

  const handleSelectedInterval = interval => setSelectedInterval(interval.toLowerCase())
  const handleSelectedType = type => setSelectedType(type.toLowerCase())

  useEffect(() => {
    shopifyProduct.variants.forEach(variant => {
      let variantInterval = ""
      let variantType = ""

      variant.selectedOptions.forEach(option => {
        if (option.name.toLowerCase() === "interval") {
          variantInterval = option.value.toLowerCase()
        }
        if (option.name.toLowerCase() === "type") {
          variantType = option.value.toLowerCase()
        }
      })

      // Check the variant options
      if (variantInterval === selectedInterval && variantType === selectedType) {
        handleVariantSelect(variant)
      }
    })
  }, [selectedInterval, selectedType])

  return (
    <div>
      {/*Intervals*/}
      {intervals.map((interval, index) => (
        <div key={`${interval}-${index}`} className={`product__type ${index === intervals.length - 1 ? "mb--20" : ""}`}>
          <div
            role="button"
            aria-label={interval}
            tabIndex={0}
            onClick={() => handleSelectedInterval(interval)}
            onKeyDown={() => handleSelectedInterval(interval)}
            className={`product__type__box ${selectedInterval === interval.toLowerCase() ? "active" : ""}`}
          >
            <div className="product__type__wrapper">
              <div className="product__type__content d-flex flex-column align-items-start flex-sm-row align-items-sm-center">
                <div className="input-field flex-shrink-0">
                  <input
                    type="radio"
                    name={`interval-${orderId}`}
                    value={interval}
                    id={`interval-${index}`}
                    checked={selectedInterval === interval.toLowerCase()}
                    onChange={e => handleSelectedInterval(e.target.value)}
                  />
                  <label htmlFor={`interval-${index}`} className="input-field__label">
                    {interval}
                  </label>
                </div>
                <p className="product--description" dangerouslySetInnerHTML={{ __html: description[index] }} />
              </div>
            </div>
          </div>
        </div>
      ))}

      <h5 className="mb--20">Choose your flavor combination:</h5>

      {/*Types*/}
      <div className="input-radio-wrapper">
        {types.map((type, index) => (
          <div key={`${type}-${index}`} className="input-field input-radio">
            <input
              type="radio"
              name={`order-type-${orderId}`}
              value={type}
              id={`type-${index}-${orderId}`}
              checked={selectedType === type.toLowerCase()}
              onChange={e => handleSelectedType(e.target.value)}
            />
            <label htmlFor={`type-${index}-${orderId}`}>{type}</label>
          </div>
        ))}
      </div>
    </div>
  )
}
export default OfferVariations
