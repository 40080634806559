import React from "react"

/**
 * @param theme - theme
 * */
const DiscountCode = ({ theme }) => {
  return (
    <div className="discount-code d-flex align-items-center justify-content-between">
      <p className="discount-code__txt">
        20% OFF checkout code: <strong className="discount-code__value">{theme.discountCode}</strong>{" "}
      </p>
      <i className="discount-code__icon fas fa-tag" />
    </div>
  )
}
export default DiscountCode
