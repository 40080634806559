import React from "react"

/**
 * @param theme - theme
 * */
const DiscountTxt = ({ theme }) => {
  return (
    <p className="discount-box__title">
      <strong>
        <span className="discount-box__highlight txt--pink">20% OFF</span> ALL SUPPLEMENTS
      </strong>{" "}
      <span className="d-block d-sm-inline-block">through {theme.discountThroughText}!</span>
    </p>
  )
}
export default DiscountTxt
